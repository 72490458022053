import './App.css';
import React, { useState, Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import logo from './images/logo.png';
import papaya from './images/papaya.jpg';
import teya from './images/teya.jpg';
import liam from './images/liam.JPG';
import xLogo from './images/logo-black.png';

function App() {
  const [openTeam, setOpenTeam] = useState(false);
  const [openMint, setOpenMint] = useState(false);
  const [openRoadmap, setOpenRoadmap] = useState(false);
  const [openNarrative, setOpenNarrative] = useState(false);

  return (
    <div className="App">
      <div className="h-screen h-full max-h-full bg-[url(images/background.jpg)] bg-cover bg-center">
        {/* Good Dogs */}
        <div className="absolute top-0 left-0 mt-4 ml-4">
          <img src={logo} alt="Logo" className="h-16 w-16 rounded-full"></img>
        </div>
        {/* Team Page */}
        {/* <button
          className="fixed p-0 bottom-0 left-0 w-full ml-5 rounded-lg bg-blue-500 text-white shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={() => setOpenBottom(!openBottom)}
        >
          Team
        </button> */}
        <div class="tab-container">
          <ul class="tabs group">
            <li> <button class="team" href="#one" onClick={() => setOpenTeam(!openTeam)}>committee</button></li> 
            <li class="mint"><button href="#two" onClick={() => setOpenMint(!openMint)}>mint</button></li>
            <li><button class="roadmap" href="#three" onClick={() => setOpenRoadmap(!openRoadmap)}>roadmap</button></li>
            <li><button class="narrative" href="#four" onClick={() => setOpenNarrative(!openNarrative)}>origins</button></li>
          </ul>
        </div>

        <div class="bottom-row">

        </div>
        {/* Team Slider */}
        <Transition.Root show={openTeam} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenTeam}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-y-full"
                    enterTo="translate-y-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-y-0"
                    leaveTo="translate-y-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-100">
                      <div className="flex h-full flex-col overflow-y-scroll bg-good-dogs-blue py-6 shadow-xl">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900 poppins-bold">committee</Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setOpenTeam(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="m-0 relative mt-6 flex-1 px-1 sm:px-2">
                          {/* Replace with your content */}
                          <CardContainer />
                          {/* /End replace */}
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Mint Slider */}
        <Transition.Root show={openMint} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenMint}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-y-full"
                    enterTo="translate-y-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-y-0"
                    leaveTo="translate-y-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-100">
                      <div className="flex h-full flex-col overflow-y-scroll bg-good-dogs-blue py-6 shadow-xl">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900 poppins-bold">minting before gta6</Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setOpenMint(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="m-0 relative mt-6 flex-1 px-1 sm:px-2">
                          {/* Mint Page */}
                          
                          {/* /End replace */}
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Roadmap Slider */}
        <Transition.Root show={openRoadmap} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenRoadmap}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-y-full"
                    enterTo="translate-y-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-y-0"
                    leaveTo="translate-y-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-100">
                      <div className="flex h-full flex-col overflow-y-scroll bg-good-dogs-blue py-6 shadow-xl">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900 poppins-bold">roadmap</Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setOpenRoadmap(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="m-0 relative mt-6 flex-1 px-1 sm:px-2">
                          {/* Mint Page */}
                          
                          {/* /End replace */}
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Narrative Slider */}
        <Transition.Root show={openNarrative} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenNarrative}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-y-full"
                    enterTo="translate-y-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-y-0"
                    leaveTo="translate-y-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-100">
                      <div className="flex h-full flex-col overflow-y-scroll bg-good-dogs-blue py-6 shadow-xl">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900 poppins-bold">origins</Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setOpenNarrative(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="m-0 relative mt-6 flex-1 px-1 sm:px-2">
                          {/* Mint Page */}
                          <p class="flex flex-row justify-left items-center poppins-bold">In a world where the heat is on and the ice caps are off,</p>
                          <p class="flex flex-row justify-left items-center poppins-bold">animals fleeing the scorching wilderness have started a mass exodus into human cities turning urban jungles into literal ones...</p>
                          <p class="flex flex-row justify-left items-center poppins-bold">Good Dogs are the last hope...</p>
                          {/* /End replace */}
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

      </div>
    </div>
  );
}


function CardContainer() {
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 content-center w-full">
        <PapayaFront />
        <TeyaFront />
        <LiamFront />
      </div>
    </div>
  );
}

function PapayaFront() {
  return (
    <div className="bg-gray-300 p-8 m-4 text-center rounded-lg shadow-lg">
      {/* Team Member's Name */}
      <h3 className="text-xl font-semibold mb-4">Nervous Papaya</h3>

      {/* Profile Picture */}
      <img
        src={papaya}
        alt="Nervous Papaya"
        className="h-40 w-40 rounded-full mx-auto mb-4 object-cover"
      />

      {/* X Logo and Profile Link */}
      <div className="flex justify-center items-center space-x-4 mt-4">
        <img src={xLogo} alt="X Logo" className="h-6 w-6" />
        <a href="https://x.com/nervouspapaya" className="text-blue-500 hover:underline">
          @nervouspapaya
        </a>
      </div>
    </div>
  );
}

function TeyaFront() {
  return (
    <div className="bg-gray-300 p-8 m-4 text-center rounded-lg shadow-lg">
      {/* Team Member's Name */}
      <h3 className="text-xl font-semibold mb-4">Teya</h3>

      {/* Profile Picture */}
      <img
        src={teya}
        alt="Teya"
        className="h-40 w-40 rounded-full mx-auto mb-4 object-cover"
      />

      {/* X Logo and Profile Link */}
      <div className="flex justify-center items-center space-x-4 mt-4">
        <img src={xLogo} alt="X Logo" className="h-6 w-6" />
        <a href="https://x.com/popeyethe13th" className="text-blue-500 hover:underline">
          @PopeyeThe13th
        </a>
      </div>
    </div>
  );
}

function LiamFront() {
  return (
    <div className="bg-gray-300 p-8 m-4 text-center rounded-lg shadow-lg">
      {/* Team Member's Name */}
      <h3 className="text-xl font-semibold mb-4">Liam</h3>

      {/* Profile Picture */}
      <img
        src={liam}
        alt="Liam"
        className="h-40 w-40 rounded-full mx-auto mb-4 object-cover"
      />

      {/* X Logo and Profile Link */}
      <div className="flex justify-center items-center space-x-4 mt-4">
        <img src={xLogo} alt="X Logo" className="h-6 w-6" />
        <a href="https://x.com/oof_liam" className="text-blue-500 hover:underline">
          @Oof_Liam
        </a>
      </div>
    </div>
  );
}


export default App;
